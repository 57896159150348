import { Box, Button, Grid, Typography } from '@mui/material';
import theme from '@presentation/styling/theme';
import { Trans } from 'react-i18next';
import type { CreatorModel } from '@domain/models';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import { useCallback, useMemo } from 'react';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';

import { AuthorHeaderContainer, AuthorHeaderWrapper, CoverImage, CreatorName, ProfileImage } from './style';
import { InfoBoxProps } from './infoBoxProps';

interface AuthorHeaderProps {
	authorData?: CreatorModel;
}

export const AuthorHeader = ({ authorData }: AuthorHeaderProps) => {
	const { isOpen, setSmartLink } = useSmartBanner();

	const link = useMemo(() => {
		if (!authorData) {
			return '';
		}

		return LinkGeneratorService.getSingularLink(LinkGeneratorService.LINK_TYPES.ARTIST, authorData.id);
	}, [authorData]);

	const redirectToApp = useCallback(
		(event: any) => {
			event.stopPropagation();

			setSmartLink(link);

			(window as any).location = link;
		},
		[link, setSmartLink]
	);

	return (
		<AuthorHeaderWrapper>
			<AuthorHeaderContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<CoverImage src={authorData?.coverImageUrl} alt={`cover-image-${authorData?.name}`} />
				<ProfileImage src={authorData?.avatarSrc} alt={`profile-image-${authorData?.name}`} />
				<Grid
					container
					width='100%'
					justifyContent='space-between'
					sx={{
						[theme.breakpoints.down('lg')]: {
							gap: '0px',
						},
						[theme.breakpoints.down('md')]: {
							gap: '16px',
						},
					}}
				>
					<Grid item lg={2} md={3} sm={12}>
						<Box display='flex' flexDirection='column'>
							<CreatorName
								variant='h4'
								fontWeight='600'
								letterSpacing='0.15px'
								textAlign='left'
								sx={{ wordBreak: 'break-all', paddingTop: 1 }}
							>
								{authorData?.name}
							</CreatorName>
						</Box>
					</Grid>
					<Grid
						container
						md={5}
						lg={6}
						sm={12}
						gap='24px'
						justifyContent='center'
						sx={{
							[theme.breakpoints.down('sm')]: {
								gap: '8px',
							},
						}}
					>
						<Grid {...InfoBoxProps} justifyContent='flex-start'>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' textAlign='center'>
								{authorData?.puzzlesCount}
							</Typography>
							<Typography variant='subtitle2' color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.contentItems' />
							</Typography>
						</Grid>
						<Grid {...InfoBoxProps}>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' textAlign='center'>
								{authorData?.followersCount}
							</Typography>
							<Typography variant='subtitle2' color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.followers' />
							</Typography>
						</Grid>
						<Grid {...InfoBoxProps}>
							<Typography variant='h4' fontWeight='600' letterSpacing='0.15px' textAlign='center'>
								{authorData?.followingCount}
							</Typography>
							<Typography variant='subtitle2' color={theme.colors.descriptionText} component='p' textAlign='center'>
								<Trans i18nKey='screens.creator.header.following' />
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='flex-end'
					>
						<Button
							variant='contained'
							style={{
								textTransform: 'none',
							}}
							size='large'
							color='buttonBlue'
							fullWidth={true}
							onClick={redirectToApp}
						>
							<Trans i18nKey='screens.creator.header.viewProfileInTheApp' />
						</Button>
					</Grid>
				</Grid>
			</AuthorHeaderContainer>
		</AuthorHeaderWrapper>
	);
};
