import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { type Theme, useTheme } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useMediaQuery } from '@mui/material';
import type { ContentItemWithCollectionsModel } from '@domain/models/ContentItemWithCollectionsModel';
import Grid from '@mui/material/Grid';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';
import { format as formatNumber } from 'numerable';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import { AuthorInfo } from '../AuthorInfo';

import {
	ContentWrapper,
	ImageWrapper,
	Wrapper,
	Image,
	Video,
	AILabel,
	AiTooltip,
	KeywordsContainer,
	KeywordsGradientRight,
	Collection,
	CloseButton,
	AIContent,
	MetadataContainer,
	FooterContainer,
	PlayInAppButton,
	MainContentContainer,
} from './styles';
import { timeSince } from './utils/date';

export type ContentDetailsModalProps = {
	onClose: () => void;
	content: ContentItemWithCollectionsModel;
	linkInApp: string;
};

export const ContentDetailsModal = (props: ContentDetailsModalProps) => {
	const { setSmartLink } = useSmartBanner();
	const navigate = useNavigate();
	const scrollContainer = useScrollContainer();

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const { onClose, content, linkInApp } = props;

	const theme = useTheme();

	const redirectToApp = useCallback(
		(event: any) => {
			event.stopPropagation();

			setSmartLink(linkInApp);

			(window as any).location = linkInApp;
		},
		[linkInApp, setSmartLink]
	);

	return (
		<Wrapper>
			<ImageWrapper>
				{content.type === 'video' && (
					<Video width='100%' height='100%' controls={false} autoPlay loop>
						<source src={content.imageSrcLowQuality} type='video/mp4' />
					</Video>
				)}
				{content.type !== 'video' && <Image src={content.imageSrcLowQuality} alt={content.name} loading='lazy' />}
				{content.isAI && (
					<AiTooltip
						enterTouchDelay={0}
						leaveTouchDelay={5000}
						title={
							<AIContent>
								<InfoOutlinedIcon fontSize='inherit' /> <Trans i18nKey='contentModal.aiTooltip' />
							</AIContent>
						}
						placement={isMobile ? 'right' : 'left'}
					>
						<AILabel component='span'>
							<Trans i18nKey='contentModal.ai' />
						</AILabel>
					</AiTooltip>
				)}
			</ImageWrapper>
			<ContentWrapper>
				<CloseButton size='small' onClick={onClose}>
					<CloseIcon />
				</CloseButton>
				<MainContentContainer sx={{ paddingTop: { xs: 1, md: 3 }, paddingBottom: { xs: 1, md: 3 } }} padding={3}>
					<Box>
						<Box sx={{ paddingRight: { xs: 0, md: 3 } }}>
							<AuthorInfo
								creator={content.author}
								color={theme.colors.text}
								onClick={() => {
									onClose && onClose();
									navigate(`/${content.author?.profileLink}`);
								}}
							/>
						</Box>

						<Box sx={{ marginTop: { xs: 1, md: 2 } }}>
							<Box sx={{ marginBottom: { xs: 1, md: 2 } }}>
								<Typography sx={{ fontSize: { xs: 18, md: 25 } }} fontWeight={600} color={theme.colors.text}>
									{content.name}
								</Typography>
							</Box>
							<Box position='relative'>
								{content.keywords && content.keywords.length > 0 && (
									<KeywordsContainer sx={{ marginBottom: { xs: 2, md: 4 } }} ref={scrollContainer.ref}>
										{content.keywords.map((keyword, index) => (
											<Chip key={index} label={keyword} sx={{ marginRight: 1 }} size='small' />
										))}
									</KeywordsContainer>
								)}
								<KeywordsGradientRight />
							</Box>
						</Box>
					</Box>
					<Grid container spacing={1} sx={{ marginBottom: { xs: 2, md: 4 } }}>
						{content.collections.map((collection) => (
							<Grid
								item
								xs={content.collections.length > 1 ? 6 : 12}
								md={content.collections.length > 1 ? 6 : 12}
								key={collection.id}
							>
								<Collection
									key={collection.id}
									image={collection?.imageSrc}
									itemsNumber={collection?.numberOfPuzzles}
									name={collection?.name}
									nameFontSize={isMobile ? 16 : 20}
									onClick={() => {
										onClose && onClose();
										navigate(`/category/${collection.id}`);
									}}
								/>
							</Grid>
						))}
					</Grid>
				</MainContentContainer>
				<FooterContainer>
					<MetadataContainer display='flex' justifyContent='space-between' marginBottom={2}>
						<Box display='flex' marginRight={1}>
							<FavoriteBorderIcon style={{ marginRight: 2 }} />
							<span>{formatNumber(content?.likes ?? 0, '0 a')}</span>
						</Box>
						<Box display='flex' marginRight={1}>
							<PlayArrowOutlinedIcon style={{ marginRight: 2 }} />
							<span>{formatNumber(content?.plays ?? 0, '0 a')}</span>
						</Box>
						<Box>
							<Typography color={theme.colors.descriptionText}>
								{content?.publishedAt && timeSince(content.publishedAt)}
							</Typography>
						</Box>
					</MetadataContainer>
					<Box sx={{ paddingRight: { xs: 0, md: 3 }, paddingLeft: { xs: 0, md: 3 }, paddingBottom: { xs: 0, md: 3 } }}>
						{linkInApp && (
							<PlayInAppButton
								variant='contained'
								style={{
									textTransform: 'none',
								}}
								size='large'
								fullWidth={true}
								onClick={redirectToApp}
								color='buttonBlue'
							>
								<Trans i18nKey='contentModal.metadata.actionButton' />
							</PlayInAppButton>
						)}
					</Box>
				</FooterContainer>
			</ContentWrapper>
		</Wrapper>
	);
};
